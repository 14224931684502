<template>
  <div class="cards">
    <div class="d-flex justify-space-between align-center mb-2" :class="isSelectingDefaultCard ? 'mb-md-7' : 'mb-md-3'">
      <div class="d-flex align-center">
        <v-btn
          v-if="isMobileAndSelectingDefaultCard"
          class="primary--text mr-2"
          color="secondary"
          large
          elevation="0"
          height="40"
          width="40"
          min-height="40"
          min-width="40"
          @click="finishSelectingDefaultCard"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <h1 class="text-h5 text-md-h4 font-weight-bold mr-4">{{ $t('cards.cards') }}</h1>
      </div>

      <template v-if="cards.length">
        <v-btn
          v-if="!isSelectingDefaultCard"
          class="cards__select-default-card-button flex-shrink-1"
          color="primary"
          large
          elevation="0"
          @click="startSelectingDefaultCard"
        >
          {{ $t('cards.choose_default_card') }}
        </v-btn>

        <div v-else class="d-flex">
          <v-btn
            v-if="!media.isMobile"
            class="primary--text mr-2"
            color="secondary"
            large
            elevation="0"
            @click="finishSelectingDefaultCard"
          >
            {{ $t('cards.back') }}
          </v-btn>
          <v-btn color="primary" large elevation="0" :loading="isSaveLoading" @click="saveCard">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            {{ $t('cards.save') }}
          </v-btn>
        </div>
      </template>
    </div>

    <cards-card-dialog ref="dialog" :card="selectedCard" />
    <cards-modal-success ref="modal" @close="modalClose" />

    <cards-info v-if="!isSelectingDefaultCard" class="mb-4 mb-md-5" />

    <cards-list v-if="isLoading">
      <cards-list-grid v-for="(_, index) in 2" :key="index" class="py-2">
        <cards-stub />
      </cards-list-grid>
    </cards-list>

    <cards-list v-else>
      <template v-if="!isSelectingDefaultCard">
        <cards-list-grid v-for="card in cards" :key="card.cardId" class="py-2">
          <cards-item
            :number="card.last4"
            :name="card.brand"
            :show-default-title="card.isDefault"
            :is-european="card.isEuropean"
            @delete="deleteCard(card)"
          />
        </cards-list-grid>

        <cards-list-grid class="py-2">
          <cards-add @click="addCard" />
        </cards-list-grid>
      </template>

      <v-radio-group v-else v-model="selectedDefaultCard" class="d-flex flex-grow-1 mt-0 pt-0" row mandatory>
        <cards-list-grid v-for="card in cards" :key="card.cardId" class="py-2" :class="{ 'px-0': media.isMobile }">
          <cards-base-item
            :number="card.last4"
            :name="card.brand"
            :show-default-title="card.isDefault"
            :is-selected="selectedDefaultCard.cardId === card.cardId"
            @click="selectDefaultCard(card)"
          >
            <v-radio class="cards__radio align-self-start ma-0" dark :value="card" />
          </cards-base-item>
        </cards-list-grid>
      </v-radio-group>
    </cards-list>
  </div>
</template>

<script>
// Services
import payments from '@/services/payments';
import analyticsService from '@/services/analytics';

// Components
import CardsList from '@/components/Cards/List.vue';
import CardsItem from '@/components/Cards/ListItem.vue';
import CardsBaseItem from '@/components/Cards/BaseListItem.vue';
import CardsAdd from '@/components/Cards/Add.vue';
import CardsStub from '@/components/Cards/Stub.vue';
import CardsCardDialog from '@/components/Cards/CardDialog.vue';
import CardsListGrid from '@/components/Cards/ListGrid.vue';
import CardsModalSuccess from '@/components/Cards/ModalSuccess.vue';
import CardsInfo from '@/components/Cards/Info.vue';

// Constants
import { CARDS_LIST, CARD_ADDING } from '@/constants/analyticsActions';

export default {
  name: 'Cards',

  components: {
    CardsList,
    CardsItem,
    CardsBaseItem,
    CardsAdd,
    CardsStub,
    CardsCardDialog,
    CardsListGrid,
    CardsModalSuccess,
    CardsInfo,
  },

  inject: ['media'],

  data: () => ({
    isLoading: true,
    cards: [],
    selectedCard: {},
    isSelectingDefaultCard: false,
    selectedDefaultCard: {},
    isSaveLoading: false,
  }),

  computed: {
    isMobileAndSelectingDefaultCard() {
      return this.media.isMobile && this.isSelectingDefaultCard;
    },

    defaultCard() {
      return this.cards.find(card => card.isDefault);
    },
  },

  async mounted() {
    const SUCCESS_STATUS = 'success';
    const status = this.$route.query?.status;

    await this.getCards();
    analyticsService.track(CARDS_LIST);

    if (status === SUCCESS_STATUS) {
      analyticsService.track(CARD_ADDING, analyticsService.createCardAddingPayload(SUCCESS_STATUS));
      this.$refs.modal.open();
    } else if (status) {
      analyticsService.track(CARD_ADDING, analyticsService.createCardAddingPayload(status));
    }
  },

  methods: {
    async getCards() {
      this.isLoading = true;

      try {
        const cards = await payments.getMethods();
        this.cards = cards.results;
      } finally {
        this.isLoading = false;
      }
    },

    async deleteCard(card) {
      this.selectedCard = card;
      const isDelete = await this.$refs.dialog.open();

      if (isDelete) {
        const cacheCards = [...this.cards];
        this.cards = this.cards.filter(currentCard => currentCard.cardId !== card.cardId);

        try {
          await payments.deleteMethod(card.cardId);
        } catch {
          this.cards = cacheCards;
        }
      }
    },

    modalClose() {
      const otherQuery = this.$route.query;

      this.$router.replace({ query: { ...otherQuery, status: undefined } });
    },

    async addCard() {
      const URI = await payments.getSessionURI();

      document.location.assign(URI);
    },

    startSelectingDefaultCard() {
      this.isSelectingDefaultCard = true;

      this.selectedDefaultCard = this.cards.find(card => card.isDefault) || this.cards[0];
    },

    finishSelectingDefaultCard() {
      this.isSelectingDefaultCard = false;
    },

    selectDefaultCard(card) {
      this.selectedDefaultCard = card;
    },

    async saveCard() {
      if (this.selectedDefaultCard.cardId === this.defaultCard?.cardId) {
        this.finishSelectingDefaultCard();
        return;
      }

      this.isSaveLoading = true;
      try {
        await payments.setDefaultPayment(this.selectedDefaultCard.cardId);
        this.getCards();
      } finally {
        this.finishSelectingDefaultCard();
        this.isSaveLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.cards {
  &__select-default-card-button {
    @media (max-width: map.get($--screens, 'xs')) {
      font-size: 12px !important;
    }
  }

  &__radio {
    width: 24px;
  }
}
</style>
