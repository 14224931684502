<template>
  <v-skeleton-loader class="mx-auto" type="image" :max-height="maxHeight" />
</template>

<script>
export default {
  name: 'CardsStub',

  inject: ['media'],

  computed: {
    maxHeight() {
      if (this.media.isMobile) {
        return 120;
      }

      return 224;
    },
  },
};
</script>
