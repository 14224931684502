<template>
  <v-dialog
    v-model="isOpen"
    content-class="elevation-0 cards-modal-success"
    :max-width="maxWidth"
    v-bind="$attrs"
    @click:outside="close"
    v-on="$listeners"
  >
    <v-card class="cards-modal-success__card pa-4 pa-md-10" outlined tile elevation="0">
      <v-card-title
        class="cards-modal-success__title justify-center text-h5 font-weight-bold text-center pa-0"
        :class="{ 'text-h6 pa-0': media.isMobile }"
      >
        {{ $t('cards.new_method_add') }}
      </v-card-title>

      <v-card-actions class="justify-center mt-4 mt-md-8 pa-0">
        <v-btn color="primary" :block="media.isMobile" large elevation="0" min-width="240" @click="close">
          {{ $t('cards.back_to_cards') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CardsModalSuccess',

  inject: ['media'],

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    maxWidth() {
      return this.media.isMobile ? 400 : 500;
    },
  },

  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
    },

    close() {
      this.isOpen = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.cards-modal-success {
  width: fit-content;

  &__title {
    word-break: break-word;
  }
}
</style>
