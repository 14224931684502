<template>
  <v-alert class="cards-info pa-2 pa-md-4" type="info" v-bind="$attrs">
    <template #prepend>
      <v-icon left>mdi-information-outline</v-icon>
    </template>

    <span class="text-body-2 text-md-body-1">
      {{ $t('cards.info') }}
    </span>
  </v-alert>
</template>

<script>
export default {
  name: 'CardsInfo',
};
</script>

<style lang="scss">
.cards-info {
  .v-alert__content {
    color: rgba($--black-color-0, 0.5) !important;
  }

  .v-icon {
    color: rgba($--black-color-0, 0.4) !important;
  }
}
</style>
