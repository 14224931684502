<template>
  <cards-base-list-item v-bind="$attrs" v-on="inheritedListeners">
    <div>
      <glass-btn @click="deleteCard">
        <v-icon>mdi-delete</v-icon>
        {{ $t('button.delete') }}
      </glass-btn>
    </div>
  </cards-base-list-item>
</template>

<script>
// Component
import GlassBtn from '@/components/GlassBtn.vue';
import CardsBaseListItem from '@/components/Cards/BaseListItem.vue';

// Utils
import { getInheritedListeners } from '@/utils/components';

export default {
  name: 'CardsListItem',

  components: { GlassBtn, CardsBaseListItem },

  inheritAttrs: false,

  computed: {
    inheritedListeners: getInheritedListeners(['delete']),
  },

  methods: {
    deleteCard() {
      this.$emit('delete');
    },
  },
};
</script>
