<template>
  <v-container fluid class="pa-0 mx-0">
    <v-row :no-gutters="media.isMobile">
      <slot></slot>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CardsList',

  inject: ['media'],
};
</script>
