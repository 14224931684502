<template>
  <info-modal v-model="isOpen" :title="title" @close="answer(false)">
    <v-btn color="secondary" large elevation="0" class="cards-dialog__button primary--text mr-2" @click="answer(false)">
      {{ $t('cards.back') }}
    </v-btn>

    <v-btn color="primary" large elevation="0" class="cards-dialog__button" @click="answer(true)">
      {{ $t('cards.yes') }}
    </v-btn>
  </info-modal>
</template>

<script>
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'CardsCardDialog',

  components: { InfoModal },

  props: {
    card: { type: Object, default: () => ({}) },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    title() {
      return `${this.$t('cards.remove')} ${this.card.brand} ····${this.card.last4}?`;
    },
  },

  methods: {
    open() {
      let success;
      let fail;

      const promise = new Promise((resolve, reject) => {
        success = resolve;
        fail = reject;
        this.isOpen = true;
      });

      this.$options.currentPromise = { resolve: success, reject: fail };

      return promise;
    },

    answer(result) {
      this.$options.currentPromise.resolve(result);
      this.$options.currentPromise = null;
      this.isOpen = false;
    },
  },

  currentPromise: null,
};
</script>

<style lang="scss">
.cards-dialog {
  &__button {
    width: calc(50% - 8px);
  }
}
</style>
