<template>
  <div
    class="cards-base-list-item pa-6 pa-md-8 d-flex flex-column justify-space-between"
    :class="{ 'cards-base-list-item--selected': isSelected }"
    v-on="$listeners"
  >
    <div class="cards-base-list-item__header d-flex justify-space-between align-center">
      <div class="d-flex flex-column">
        <div class="text-h6">
          {{ cardNumber }}
        </div>

        <div v-if="showDefaultTitle" class="text-body-2">
          {{ $t('cards.main_card') }}
        </div>
      </div>

      <slot />
    </div>

    <v-icon v-if="showMark" class="cards-base-list-item__mark white rounded-circle" size="32">
      mdi-checkbox-marked-circle
    </v-icon>

    <div class="text-h6 mt-3 mt-md-0">
      {{ name }}
      <span class="cards-base-list-item__is-eu text-h6">{{ european }}</span>
    </div>

    <img class="cards-base-list-item__dots" src="@/assets/img/white-dots.svg" alt width="3" height="34" />
  </div>
</template>

<script>
export default {
  name: 'CardsBaseListItem',

  props: {
    number: { type: String, required: true },
    name: { type: String, required: true },
    isSelected: { type: Boolean, default: false },
    showDefaultTitle: { type: Boolean, default: false },
    showMark: { type: Boolean, default: false },
    isEuropean: { type: Boolean, default: false },
  },

  computed: {
    cardNumber() {
      return `••••${this.number}`;
    },

    european() {
      return this.isEuropean ? this.$t('cards.eu') : this.$t('cards.non_eu');
    },
  },
};
</script>

<style lang="scss">
.cards-base-list-item {
  background-color: $--cards-list-item-background;
  border-radius: 4px;
  color: $--cards-list-item-font-color;
  min-height: 224px;
  position: relative;
  transition: background 0.2s;

  &:hover:not(#{&}--selected) {
    background-color: $--cards-list-item-background-hovered;
  }

  &--selected {
    background-color: $--cards-list-item-background-selected;
  }

  &__header {
    min-height: 44px;
  }

  &__dots {
    position: absolute;
    right: 32px;
    bottom: 0;
  }

  &__mark {
    position: absolute !important;
    right: -2px;
    top: -16px;
    color: #4caf50 !important;
  }

  &__is-eu {
    color: rgba($--white-color-0, 0.5);
    font-weight: normal !important;
  }

  @media (max-width: map.get($--screens, 'sm')) {
    min-height: 120px;
  }
}
</style>
