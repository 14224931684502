<template>
  <div
    v-ripple="{ center: true, class: 'white--text' }"
    class="cards-add pa-8 d-flex flex-column justify-center align-center rounded"
    @click.self="click"
  >
    <v-btn color="primary" large @click="click">
      <v-icon>mdi-plus</v-icon>
      {{ $t('button.add_card') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CardsAdd',

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.cards-add {
  cursor: pointer;
  background: rgba($--cards-add-background, 0.4);
  border: 1px solid rgba($--cards-add-border, 0.1);
  min-height: 224px;

  @media (max-width: map.get($--screens, 'sm')) {
    min-height: 120px;
  }
}
</style>
